import { createJSONStorage, persist } from "zustand/middleware";
import { useGlobalMyInfo } from "./useGlobalMyInfo";
import { create } from 'zustand';



interface ManagementState {
    globalManagement: boolean;
    setGlobalManagement: (by: boolean) => void;
}

export const useGlobalManagementStore = create<ManagementState>()(
    persist(
        (set) => {
            return {
                globalManagement: false,
                setGlobalManagement: (by) => set((state) => ({ globalManagement: by })),
            };
        },
        {
            name: 'wood-global-management', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
        },
    )
);